import React from 'react';
import lanaImage from '../images/lana.jpg'; 
import sarahImage from '../images/sarah.jpg'; 
import wayneImage from '../images/wayne.jpg'; 
import joanneImage from '../images/joanne.jpg'; 

export default function TestimonialsSection() {
    const sectionStyle: React.CSSProperties = {
        textAlign: 'center',
        padding: '50px',
        backgroundColor: '#f8f8f8', // Or any other background color
    };

    const testimonialStyle: React.CSSProperties = {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '23%', // This allows a little space for margins
        margin: '1%',
        padding: '20px',
        backgroundColor: 'white', // Assuming a white background for the testimonial box
        borderRadius: '5px', // Round corners for the testimonial box
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // A subtle shadow to lift the testimonial box
    };

    const imageStyle: React.CSSProperties = {
        width: '80px', // Adjust as necessary
        height: '80px', // Adjust as necessary
        borderRadius: '50%', // Circular images
        marginBottom: '10px',
    };

    const testimonialTextStyle: React.CSSProperties = {
        fontStyle: 'italic',
        marginBottom: '10px',
    };

    const nameStyle: React.CSSProperties = {
        fontWeight: 'bold',
    };

    return (
        <div style={sectionStyle}>
            <h2>WHAT THEY SAY...</h2>
            <p>What our clients are saying about us?</p>
            <div style={testimonialStyle}>
                <img src={lanaImage} alt="Lana" style={imageStyle} />
                <div style={testimonialTextStyle}>You're a star!</div>
                <div style={nameStyle}>Lana</div>
                <div>Pontefract</div>
            </div>
            <div style={testimonialStyle}>
                <img src={sarahImage} alt="Sarah" style={imageStyle} />
                <div style={testimonialTextStyle}>She had a fab time, thank you for taking her</div>
                <div style={nameStyle}>Sarah</div>
                <div>Ackworth</div>
            </div>
            <div style={testimonialStyle}>
                <img src={wayneImage} alt="Wayne" style={imageStyle} />
                <div style={testimonialTextStyle}>Brilliant, thank you for your help, will be using you again!</div>
                <div style={nameStyle}>Wayne</div>
                <div>Castleford</div>
            </div>
            <div style={testimonialStyle}>
                <img src={joanneImage} alt="Joanne" style={imageStyle} />
                <div style={testimonialTextStyle}>Brilliant, thank you so much</div>
                <div style={nameStyle}>Joanne</div>
                <div>Featherstone</div>
            </div>
        </div>
    );
}
