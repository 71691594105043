import React from 'react';

export default function Footer() {
    const containerStyle: React.CSSProperties = {
        backgroundColor: '#f0f4f8', // adjust the color to match your theme
        padding: '20px',
        color: '#333', // adjust the text color as needed
        lineHeight: '1.6', // makes the text more readable
    };

    const headingStyle: React.CSSProperties = {
        color: '#00a', // color for the heading
        marginBottom: '10px',
    };

    const addressStyle: React.CSSProperties = {
        marginBottom: '10px',
    };

    const phoneTextStyle: React.CSSProperties = {
        color: '#00a', // color for the phone text
        fontWeight: 'bold',
    };

    const phoneLinkStyle: React.CSSProperties = {
        textDecoration: 'none',
        color: '#00a', // color for the phone link
    };

    return (
        <div style={containerStyle}>
            <h2 style={headingStyle}>FIND US</h2>
            <address style={addressStyle}>
                Connies Friends,<br />
                116B Station Lane,<br />
                Featherstone,<br />
                Pontefract,<br />
                WF7 6EG
            </address>
            <div>
                <span style={phoneTextStyle}>Call us on 📞 </span>
                <a href="tel:+07710235951" style={phoneLinkStyle}>07710 235 951</a>
                <span> If we are out walking dogs and cannot answer, leave a message and we will ring you back as soon as possible.</span>
            </div>
        </div>
    );
}
