import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import ServicesSection from './components/services';
import TestimonialsSection from './components/testimonials';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <ServicesSection />
      <TestimonialsSection />
      <Footer />
    </div>
  );
}

export default App;
