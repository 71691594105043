import React from 'react';
import dogWalkingImage from '../images/dogwalking.jpg'; 
import dayCareImage from '../images/daycare.jpg'; 
import boardingImage from '../images/boarding.jpg'; 

export default function ServicesSection() {
    const sectionStyle: React.CSSProperties = {
        textAlign: 'center',
        padding: '50px',
    };

    const serviceStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '30%',
        margin: '0 1.5%',
        verticalAlign: 'top', // This ensures that the divs are aligned properly
    };

    const imageStyle: React.CSSProperties = {
        width: '100%', // This will make the image responsive within the container
        height: '400px', // Set a fixed height for all images
        objectFit: 'cover', // This will cover the area without stretching the image
        marginBottom: '15px',
        borderRadius: '5px',
    };

    return (
        <div style={sectionStyle}>
            <h2>OUR SERVICES</h2>
            <div style={serviceStyle}>
                <h3>Dog Walking</h3>
                <img src={dogWalkingImage} alt="Dog Walking" style={imageStyle} />
            </div>
            <div style={serviceStyle}>
                <h3>Day Care</h3>
                <img src={dayCareImage} alt="Day Care" style={imageStyle} />
            </div>
            <div style={serviceStyle}>
                <h3>Boarding</h3>
                <img src={boardingImage} alt="Boarding" style={imageStyle} />
            </div>
        </div>
    );
}