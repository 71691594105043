import background from '../images/background.jpg';

export default function Hero() {
    const heroBannerStyle: React.CSSProperties = {
        width: '100%',
        height: '600px', // You can adjust the height as needed
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white', // Adjust text color as needed
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Optional text shadow for better readability
        filter: 'brightness(100%)', // Adjust the percentage to control brightness
    };

     // Styles for the main heading
     const headingStyle: React.CSSProperties = {
        fontSize: '48px', // Adjust the size as needed
        // Any additional styling for the heading
    };

    // Styles for the subtext
    const subTextStyle: React.CSSProperties = {
        fontSize: '24px', // Adjust the size as needed
        // Any additional styling for the subtext
    };

    return (
        <div style={heroBannerStyle}>
            <h1 style={headingStyle}>Connies Friends</h1>
            <p style={subTextStyle}>Caring for your dog is our passion</p>
        </div>
    );
}